































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import usePrinter from "@/use/templates/printer";
import useTemplate from "@/use/templates/template";

export default defineComponent({
  props: {
    participants: {
      type: Array,
      required: true,
      default: () => [],
    },
    status: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const {
      isActive,
      printers,
      selectedPrinter,
      selectPrinter,
      connect,
      disconnect,
      printImage,
    } = usePrinter();
    const { render } = useTemplate({ root });

    const state = reactive({
      items: [],
      template: {} as any,
      printed: false,
      error: false,
      certificatesToPrint: [] as any,
    });

    const buttonDisabled = computed(() => {
      if (state.template && state.template.id) {
        return false;
      } else return true;
    });

    const fetchCertificates = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}/certificate-template`)
        .then(({ data }) => {
          state.items = data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(fetchCertificates);

    const printItems = computed(() => {
      if (props.participants && props.participants.length) {
        const printItems = [];
        let item: any;
        for (item of props.participants) {
          if (item && item.quantity > 1) {
            for (let i = 0; i < item.quantity; i++) {
              printItems.push(item);
            }
          } else printItems.push(item);
        }
        return printItems;
      }
    });

    const getCertificates = async () => {
      const certificates = [];
      if (printItems.value && state.template && state.template.id) {
        let item: any;
        for await (item of printItems.value) {
          const renderImage = await render(
            { format: state.template.format, items: state.template.items },
            item
          );

          const format = state.template.format;
          const density = 300;
          const units = "in";
          const w = format.width / density;
          const h = format.height / density;
          const orientation = w > h ? "landscape" : "portrait";
          const width = orientation === "landscape" ? h : w;
          const height = orientation === "portrait" ? h : w;
          const size = { width, height };
          const jobName = `Certyfikat - ${item.firstName} ${item.lastName}`;

          const config = {
            format,
            density,
            units,
            w,
            h,
            orientation,
            width,
            height,
            size,
            jobName,
          };

          if (renderImage)
            certificates.push({ certificate: renderImage, config });
        }
        state.certificatesToPrint = certificates;
        return certificates;
      }
    };

    watch(() => state.template, getCertificates);

    const print = () => {
      if (state.certificatesToPrint && state.certificatesToPrint.length > 0) {
        let item: any;
        for (item of state.certificatesToPrint) {
          printImage(item.certificate, item.config)
            .then(() => {
              state.printed = true;
              state.error = false;
            })
            .catch((e) => {
              state.printed = false;
              state.error = true;
              console.log(e);
            });
        }
      }
    };

    const closeDialog = () => {
      emit("close");
      disconnect();
    };

    return {
      state,
      buttonDisabled,
      printItems,
      print,
      isActive,
      printers,
      selectedPrinter,
      selectPrinter,
      connect,
      disconnect,
      printImage,
      closeDialog,
    };
  },
});
